import React from 'react';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Menu from '../../components/navigation';
import ProjectsText from '../../components/projects/text';
import ProjecsGallery from '../../components/projects/gallery';
import ProjectItem from '../../components/projects/item';
import Image from '../../components/projects/image';

import egp1 from '../../images/projects/egp/egp1.jpg';
import egp12x from '../../images/projects/egp/egp1@2x.jpg';
import egp2 from '../../images/projects/egp/egp2.jpg';
import egp22x from '../../images/projects/egp/egp2@2x.jpg';
import egp3 from '../../images/projects/egp/egp3.jpg';
import egp32x from '../../images/projects/egp/egp3@2x.jpg';
import egp4 from '../../images/projects/egp/egp4.jpg';
import egp42x from '../../images/projects/egp/egp4@2x.jpg';

const title = 'Entwurf des Leitsystems für EGP Gebäude in Trier.';
const text =
	'Für moderne und schlichte Räume wurde ein buntes, spannendes Leitsystem entworfen. Die Beschriftung wurde aus dem Material ausgefräst.';

/**
 * Page
 */
const Page = () => {
	return (
		<Layout>
			<SEO title="" />
			<Menu selected="projekte" />
			<ProjectsText text={text} title={title} />
			<div className="page-width align-center"></div>
			<ProjecsGallery>
				<ProjectItem>
					<Image src={egp1} src2={egp12x} />
				</ProjectItem>
				<ProjectItem>
					<Image src={egp2} src2={egp22x} />
				</ProjectItem>
				<ProjectItem>
					<Image src={egp3} src2={egp32x} />
					<Image src={egp4} src2={egp42x} />
				</ProjectItem>
			</ProjecsGallery>
		</Layout>
	);
};

export default Page;
